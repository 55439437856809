/**
 * Opens the specified URL in a new browser window/tab, if the platform is a browser.
 *
 * @param url - The URL to open.
 * @param platform - An instance of the PlatformService.
 */
import { PlatformService } from '@services/platform.service';

export function openUrl(url: string, platform: PlatformService): void {
  if (platform.isPlatformBrowser()) {
    window.open(url, '_blank');
  }
}
